.page-container {
  overflow: hidden;
}

.cookies {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 1);
  padding: 30px;
  font-size: 15px;
  z-index: 100000;
  transition: opacity 1s;
}

.no-cookies {
  transition: opacity 1s;
  opacity: 0;
}

.main-with-header {
  background-color: #ffffff;
}

.main {
  min-height: calc(100vh - 237px);
  position: relative;
}

.main-content {
  padding-top: 50px;
  padding-bottom: 36px;
}

.logo {
  cursor: pointer;
}

.footer {
  background-color: #f3f5f7;
  min-height: 157px;
}

.info-footer {
  background-color: #e8eaf0;
  padding-top: 12px;
  padding-bottom: 12px;
}

.text-center {
  text-align: center;
}

.footer-content {
  padding-top: 28px;
  padding-bottom: 15px;
}

.footer-link {
  font-weight: 500;
  font-size: 12px;
  color: #717171;
  line-height: 131.19%;
  margin-bottom: 10px;
  cursor: pointer;
}

.fake-footer-link {
  width: 10px;
  height: 15px;
  margin-bottom: 10px;
}

.footer-icon-description {
  font-style: normal;
  font-weight: 500;
  font-size: 7px;
  line-height: 10px;
  text-align: center;
  color: #717171;
}

.footer-icons {
  margin-top: 8px;
}

.footer-icon-container {
  height: 26px;
  margin-bottom: 7px;
}

.copyrights {
  color: #9fa0a7;
  font-size: 10px;
  height: 20px;
  font-weight: 500;
}

.white-container {
  background-color: rgba(255, 255, 255, 0.5);
  margin-top: 30px;
}

.progress {
  height: 6px;
  background-color: #f3f5f7;
  width: 100%;
}

.progress-state {
  height: 6px;
  width: 33%;
  background-color: #3400c8;
}

.progress-state-2 {
  width: 66%;
}

.progress-state-3 {
  width: 100%;
}

.box-step {
  line-height: 24px;
  border: 1.5px solid #3400c8;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 12px;
  text-align: center;
  color: #3400c8;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: 600;
  margin-right: 14px;
  font-family: Lato;
  margin-bottom: 12px;
}

.box-step-2 {
  margin: 0;
}

.box-title {
  font-family: Livvic;
  font-weight: 500;
  font-size: 25px;
  color: #ffffff;
  line-height: 35px;
}

.box-title2 {
  font-weight: 700;
}

.fivestar-container {
  margin-top: 0px;
}

.avis-title {
  font-family: 'Livvic';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #5158F5;
  margin-top: 12px;
}

.avis-subtitle {
  font-family: 'Livvic';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 16px;
}

.avis-commentary-title {
  margin-top: 15px !important;
  margin-bottom: 4px !important;
  color: #5158F5;
  font-family: 'Livvic';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  width: 100%;
}

.avis-commentary-value {
  font-family: 'Livvic';
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 13px;
  color: #1B1464;
  text-align: left;
}

.avis-commentary {
  border-radius: 4px;
  background-color: white;
  padding: 8px;
  width: 100%;
}

.avis-content {
  font-family: 'Livvic';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF;
  margin-top: 20px !important;
}

.avis-nothanks-item {
  margin-top: 20px !important;
}

.avis-nothanks {
  font-family: 'Livvic';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  /* identical to box height */

  text-align: center;
  text-decoration-line: underline;

  color: #717171;

}

.avis-checks {
  justify-content: space-between;
  margin-top: 24px !important;
}

.avis-check-text {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120.19%;
  text-decoration-line: underline;
  color: #FFFFFF;
  margin-left: 6px !important;
  margin-top: -3px !important;
}

.avis-clicked {
  font-family: 'Livvic';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #3400C8;
  margin-top: 20px !important;

}

.main-form {
  padding-top: 18px;
}

.MuiCheckbox-root {
  color: #717171;
}

.label-input {
  color: #717171;
  font-weight: 400;
  line-height: 20px;
}

.checkbox-input {
  margin-left: -9px !important;
  margin-top: 7px;
}

.cta {
  font-weight: 700 !important;
  text-transform: none !important;
  font-size: 15px !important;
  line-height: 18px !important;
  border-radius: 4px !important;
  padding-left: 16px !important;
  padding-right: 13px !important;
  height: 40px !important;
  margin-top: 27px !important;
  box-shadow: none !important;
  z-index: 1000;
}

.cta-avis {
  font-weight: 700 !important;
  text-transform: none !important;
  font-size: 15px !important;
  line-height: 18px !important;
  border-radius: 4px !important;
  padding-left: 48px !important;
  padding-right: 48px !important;
  height: 48px !important;
  margin-top: 27px !important;
  box-shadow: none !important;
  z-index: 1000;
}

.cta-card {
  font-weight: 700 !important;
  text-transform: none !important;
  font-size: 15px !important;
  line-height: 18px !important;
  border-radius: 4px !important;
  padding-left: 16px !important;
  padding-right: 13px !important;
  height: 40px !important;
  box-shadow: none !important;
  z-index: 1000;
  margin-left: 20px !important;
}

.cta-payment {
  font-weight: 700 !important;
  text-transform: none !important;
  font-size: 15px !important;
  line-height: 18px !important;
  border-radius: 4px !important;
  padding-left: 16px !important;
  padding-right: 13px !important;
  height: 40px !important;
  margin-top: 0px !important;
  box-shadow: none !important;
  z-index: 1000;
}

.cta-payment-no-margin {
  font-weight: 700 !important;
  text-transform: none !important;
  font-size: 15px !important;
  line-height: 18px !important;
  border-radius: 4px !important;
  padding-left: 16px !important;
  padding-right: 13px !important;
  height: 40px !important;
  box-shadow: none !important;
  z-index: 1000;
}


.cta-close {
  margin-top: 11px !important;
}

.cta img {
  margin-left: 20px;
}

.cta-avis img {
  margin-left: 20px;
}


.cta-payment img {
  margin-left: 20px;
}

.info-title {
  margin-top: 17px;
  font-size: 18px;
  line-height: 26px;
  color: #3400c8;
  font-weight: 400;
}

.info-body {
  margin-top: 4px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  color: #9fa0a7;
  margin-bottom: 20px;
}

.societe-dialog {
  border-radius: 0px !important;
}

.dialog-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 34px;
  color: #3400c8;
}

.societe-container {
  background-color: #0de3b0;
  border-radius: 10px;
  padding: 13px 20px;
  margin-bottom: 16px;
  cursor: pointer;
}

.info-dialog {
  font-size: 18px;
  line-height: 30px;
  color: #3400c8;
}

.info-title-dialog {
  color: #ffffff;
  font-weight: 700;
}

.info-value-dialog {
  text-transform: capitalize;
}

.dialog-button {
  text-transform: none !important;
  border-radius: 4px !important;
  font-size: 15px !important;
  width: 129px !important;
}

.dialog-button img {
  margin-left: 20px;
}

.MuiDialog-paper {
  border-radius: 0px !important;
  box-shadow: none !important;
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.01) !important;
  backdrop-filter: blur(5px);
}

.search-title {
  font-size: 24px;
  line-height: 34px;
  color: #3400c8;
  font-weight: 700;
}

.search-subtitle {
  font-size: 12px;
  line-height: 17px;
  color: #3400c8;
  font-weight: 400;
  margin-bottom: 15px;
}

.white-email-container {
  margin-top: 30px;
}

.doc-container {
  position: relative;
}

.doc-desktop {
  margin-top: 8px;
  margin-right: 14px;
  max-width: 100%;
}

.doc-container-mobile {
  position: relative;
  margin-top: 15px;
  text-align: center;
}

.specimen-desktop {
  position: absolute;
  left: 47px;
  top: 88px;
  max-width: 100%;
}

.doc-mobile {
  max-width: 100%;
}

.specimen-mobile {
  position: absolute;
  left: calc(50% - 58px);
  top: 20px;
  max-width: 100%;
}

.white-email-container-right {
  background-color: rgba(255, 255, 255, 0.5);

  padding: 28px 18px 30px 18px;
}

.box-subtitle {
  color: #717171;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
}

.email-cta {
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  height: 36px !important;
  border-radius: 4px !important;
  width: 100% !important;
}

.email-informations {
  margin-top: 33px;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #ffffff;
  max-width: 80%;
}

.email-icon-description {
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  color: #717171;
}

.price-icon-description {
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  color: #9fa0a7;
  margin-top: 9px !important;
}

.email-icon {
  height: 35px;
}

.email-icons-container {
  margin-top: 66px;
}

.label-email {
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #3400c8;
  font-weight: 500;
  margin-top: 12px;
}

.main-form-email {
  margin-top: 7px;
}

.price-info {
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #717171;
}

.price-info-2 {
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #717171;
}

.price-info-sub-2 {
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #717171;
}

.price-divider {
  height: 1px;
  width: 100%;
  background-color: #ffffff;
  margin-top: 23px;
  margin-bottom: 18px;
}

.price-divider-2 {
  height: 1px;
  width: 100%;
  background-color: #ffffff;
  margin-top: 5px;
  margin-bottom: 5px;
}

.price-detail {
  margin-top: 25px;
  background-color: #ffffff;
  padding: 9px 11px;
  border-radius: 5px;
}

.price-total {
  margin-bottom: 32px;
  padding: 0px 11px;
}

.price-total-2 {
  padding: 0px 11px;
}

.price-information-title {
  font-size: 12px;
  line-height: 14px;
  color: #717171;
  font-weight: 700;
  margin-bottom: 10px;
}

.price-information {
  font-size: 12px;
  line-height: 14px;
  color: #717171;
  margin-bottom: 10px;
}

.payment-container {
  padding: 20px 40px;
}

.payment-label {
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #3400c8;
}

.payment-label-2 {
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  color: #ffffff;
}

.card-icon-container {
  height: 50px;
  display: table-cell;
  vertical-align: middle;
  cursor: pointer;
}

.card-icon {
  vertical-align: middle;
  width: 61px;
  height: 34px;
  background: #ffffff;
  border-radius: 7px;
  text-align: center;
  position: relative;
  border: 2px solid #f3f5f7;
  box-sizing: border-box;
}

.card-icon img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
}

.card-icon-am {
  cursor: pointer;
  width: 61px;
  height: 34px;
}

.card-icon-am img {
  width: 61px;
  height: 34px;
  border-radius: 7px;
  border: 2px solid #f3f5f7;
  box-sizing: border-box;
}

.card-icon-selected-am img {
  border-radius: 7px;
  border: 2px solid #0de3b0;
  box-sizing: border-box;
  transition: 0.4s;
}

.card-icon-selected {
  border-radius: 7px;
  border: 2px solid #0de3b0;
  box-sizing: border-box;
  transition: 0.4s;
}

.checkbox-input-payment {
  margin-top: 13px;
  margin-left: -9px !important;
}

.label-input-small {
  color: rgba(113, 113, 113, 0.6);
  font-size: 11px;
  line-height: 13px;
  display: block;
}

.label-input-small-2 {
  color: #ffffff;
  font-size: 11px;
  line-height: 13px;
  display: block;
  font-weight: 300;
}

.input-cb {
  margin-top: 4px;
  padding: 6px 13px;
  background-color: #f9f9f9;
  border-radius: 5px;
}

.input-cb-2 {
  margin-top: 4px;
  padding: 10px 16px;
  background-color: #ffffff;
  border-radius: 5px;
}

.title-confirmation {
  font-size: 30px;
  line-height: 43px;
  text-align: center;
  font-weight: 500;
  color: #3400c8;
  text-align: center;
}

.confirmation-information {
  font-size: 18px;
  color: #717171;
  font-weight: 400;
  line-height: 35px;
  text-align: center;
}

.confirmation-information-body {
  font-size: 18px;
  color: #717171;
  font-weight: 400;
  line-height: 35px;
  text-align: center;
}

.cookie-button {
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 700 !important;
  text-transform: none !important;
  height: 36px !important;
  border-radius: 0px !important;
}

.header-button {
  text-transform: none !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  white-space: nowrap;
}

.forgot-password {
  margin-top: 20px;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}

.forgot-password-description {
  margin-bottom: 10px;
  font-size: 15px;
  color: #3400c8;
}

.button-menu {
  text-transform: none !important;
  font-size: 16px !important;
  color: #ffffff !important;
  background-color: rgba(113, 113, 113, 0.4) !important;
  border-radius: 0px !important;
  margin-bottom: 10px !important;
  font-weight: 500 !important;
}

.button-menu-selected {
  background-color: #0de3b0 !important;
}

.account-container {
  background-color: rgba(255, 255, 255, 0.5);
}

.account-title {
  border-bottom: 2px solid #0de3b0;
  font-size: 26px;
  padding: 16px 0px;
}

.account-body {
  padding: 20px;
}

.loading-text {
  font-weight: 300;
  color: #3400c8;
  font-style: italic;
}

.account-block {
  margin-bottom: 50px;
}

.account-block-title {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 20px;
  line-height: 1.41;
  color: #3400c8;
}

.account-block-data {
  font-size: 16px;
  font-weight: 300;
}

.account-block-value {
  font-size: 16px;
  font-weight: 600;
}

.account-block-value-important {
  font-size: 18px;
  font-weight: 600;
  color: #c1272d;
  font-style: italic;
  letter-spacing: 1px;
}

.contained-button {
  text-transform: none !important;
  font-size: 15px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  margin-top: 15px !important;
}

.title-generic {
  font-size: 30px;
  line-height: 43px;
  font-weight: 500;
  color: #3400c8;
  border-bottom: 2px solid #0de3b0;
  padding-bottom: 20px;
}

.subtitle-generic {
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #3400c8;
  margin-bottom: 10px;
}

.subtitle-generic2 {
  font-size: 15px;
  line-height: 141.69%;
  color: #3400c8;
  margin-bottom: 5px;
  font-weight: 600;
}

.subtitle-generic3 {
  font-size: 15px;
  line-height: 141.69%;
  text-decoration: underline;
  margin-bottom: 5px;
  font-weight: 600;
}

.body-generic {
  font-size: 15px;
  line-height: 141.69%;
  color: #717171;
  margin-bottom: 25px;
}

.body-generic-pad {
  padding-left: 25px;
}

table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 10px;
}

.form-lign-generic {
  margin-bottom: 15px;
}

.form-lign-generic .payment-label {
  margin-bottom: 4px;
}

.blue-text {
  color: #3400c8;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.card-icon-container img {
  width: 61px;
  height: 34px;
}

.info-logos {
  font-size: 12px;
  line-height: 15px;
  color: #3400c8;
  margin-bottom: 4px;
}

.icon-menu {
  width: 20px;
  margin-right: 5px;
}

.color-white {
  color: #ffffff;
}

.color2 {
  color: #0de3b0;
}

.nowrap {
  white-space: nowrap;
}

.full-width {
  width: 100% !important;
}

.underline-button {
  color: #3400c8 !important;
  line-height: 20px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  text-decoration-line: underline !important;
  text-transform: none !important;
}

.document-count {
  color: #3400c8;
  font-size: 25px;
  line-height: 40px;
}

.drop-zone {
  max-width: 100px;
  position: relative;
}

.absolute-full-upper {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.document-container {
  height: 250px;
  border: 1px solid #dadce0;
  border-radius: 6px;
}

.preview-doc {
  height: calc(100% - 68px);
  background-color: #f5f5f5;
  position: relative;
  text-align: center;
  overflow: hidden;
}

.delete-document-button {
  position: absolute !important;
  right: 0px;
  z-index: 2;
}

.download-document-button {
  position: absolute !important;
  left: 0px;
  z-index: 2;
}

.doc-info {
  padding: 10px;
  border-top: 1px solid #dadce0;
  position: relative;
}

.doc-name {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pdf-image {
  margin-top: 40px;
}

.partner-image {
  width: initial;
}

.lp-note-asterix {
  font-size: 18;
}

.lp-section-title-description-companies {}

.box-due-amount {
  font-size: 17px;
  line-height: 24px;
  font-weight: 300;
  margin-top: 10px;
  margin-bottom: 20px;
}

.card-icon-container-2 {
  background-color: #ffffff;
  width: 40px;
  border-radius: 7px;
  height: 22px;
  position: relative;
}

.card-icon-container-3 {
  background-color: #ffffff;
  width: 80px;
  border-radius: 7px;
  height: 32px;
  position: relative;
}

.card-icon-container-2 img {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

.icon-card-2 {
  width: 100%;
}

.payment2-right-part-title {
  color: #717171;
  font-size: 24px;
  line-height: 34px;
}

.payment2-right-part-divider {
  height: 1px;
  width: 100%;
  background-color: #e8eaf0;
  margin-top: 14px;
  margin-bottom: 14px;
}

.payment2-right-part-title2 {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #9fa0a7;
}

.payment2-right-part-title3 {
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  color: #717171;
}

.payment2-right-part-description {
  font-size: 13px;
  font-weight: 700;
  line-height: 19px;
  color: #9fa0a7;
}

.button-container-payment2 {
  margin-top: 25px;
}

.footer-logo-payment {
  width: 180px;
  max-width: 100%;
}

.changeCardButton {
  margin-top: 0px;
}

.text-header-payment{
  font-size: 16px ;
  font-family: Livvic;
  font-weight: 400;
  line-height: 22px;
  color:#717171;
  margin-top: 5px;
}
.specimen-img {
  margin-top: 5px;
}

@media (max-width: 768px) {
  .changeCardButton {
    margin-top: 20px !important;
  }

  .title-generic {
    font-size: 20px;
    line-height: 28px;
    text-align: center;
  }

  .display-desktop {
    display: none !important;
  }

  .main-content {
    padding-top: 40px;
  }

  .cookies {
    font-size: 15px;
    padding: 15px;
  }

  .footer-link-container {
    padding-left: 42px;
    padding-right: 42px;
  }

  .footer-logo-mobile {
    margin-top: 51px;
    margin-bottom: 45px;
  }

  .copyrights {
    margin-top: 15px;
  }

  .MuiTypography-h1 {
    text-align: center;
    font-size: 18px !important;
    line-height: 25px !important;
  }

  .MuiTypography-h2 {
    text-align: center;
    font-size: 14px !important;
    line-height: 20px !important;
  }

  .green-container-content {
    padding: 28px 18px 106px 18px;
    background-color: #0de3b0;
    color: #ffffff;
  }

  .white-container-content {
    padding: 28px 18px 30px 18px;
  }

  .white-email-container-right {
    padding: 28px 18px 30px 18px;
  }

  .white-container-search {
    padding-bottom: 75px;
  }

  .box-step {
    line-height: 27px;
    font-size: 12px;
    margin-bottom: 22px;
  }

  .fivestar-container {
    margin-top: 32px;
  }

  .avis-title {
    font-family: 'Livvic';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 19px;
    text-align: center;
    color: #5158F5;
  }

  .avis-subtitle {
    font-family: 'Livvic';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-align: center;

    color: #FFFFFF;
  }

  .avis-content {
    font-family: 'Livvic';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #FFFFFF;
  }

  .avis-nothanks {
    font-family: 'Tondo';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    text-decoration-line: underline;
    color: #717171;
    margin-top: 12px;
  }

  .avis-checks {
    justify-content: space-evenly;
    margin-top: 60px !important;
  }

  .avis-check-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 120.19%;
    text-decoration-line: underline;
    color: #FFFFFF;
  }

  .box-title {
    font-size: 18px;
    line-height: 26px;
    text-align: center;
  }

  .box-title2 {
    text-align: left;

  }

  .label-input {
    font-size: 12px;
  }

  .cta {
    margin-top: 30px !important;
  }

  .cta-close {
    margin-top: 19px !important;
  }

  .white-container {
    background-color: rgba(255, 255, 255, 0.8);
  }

  .white-email-container-right {
    background-color: rgba(255, 255, 255, 0.8);
  }

  .dialog-title {
    font-size: 20px;
  }

  .info-dialog {
    font-size: 14px;
  }

  .dialog-button {
    margin-top: 17px !important;
  }

  .dialog-button {
    font-size: 18px !important;
  }

  .search-title {
    font-size: 18px;
  }

  .search-subtitle {
    font-size: 12px;
  }

  .box-subtitle {
    font-size: 14px;
  }

  .email-icons-container {
    margin-top: 40px;
  }

  .email-cta {
    width: 47%;
    float: right;
  }

  .label-email {
    margin-top: 10px;
    font-size: 13px;
  }

  .box-step-less-place {
    margin-bottom: 13px;
  }

  .price-detail {
    margin-top: 20px;
  }

  .price-total {
    margin-bottom: 0px;
  }

  .price-total-2 {
    margin-bottom: 0px;
  }

  .payment-container {
    padding: 20px 20px 27px 20px;
  }

  .input-cb {}

  .title-confirmation {
    font-size: 20px;
    line-height: 28px;
  }

  .confirmation-information {
    font-size: 14px;
    line-height: 20px;
  }

  .confirmation-information-body {
    font-size: 14px;
    line-height: 20px;
  }

  .button-menu {
    font-size: 16px !important;
  }

  .account-container {
    background-color: rgba(255, 255, 255, 0.8);
  }

  .account-title {
    font-size: 18px;
    padding: 15px 10px;
  }

  .right-side-image-container {
    position: relative;
    margin-bottom: -130px !important;
    text-align: center;
  }

  .right-side-image {
    max-width: 115%;
    position: relative;
    top: -130px;
    min-height: 300px;
  }

  .right-side-image-search {
    max-width: 100%;
    margin-top: 19px;
    min-height: 300px;
  }

  .right-side-image-email {
    margin-top: 40px;
    min-height: 300px;
  }

  .email-informations {
    max-width: 100%;
    text-align: center;
  }

  .main-form-email {
    text-align: center;
  }

  .green-container-content-no-image {
    padding: 10px 16px 70px;
    background-color: #0de3b0;
    color: #ffffff;
  }

  .green-container-content-no-image-avis {
    padding: 10px 16px 32px;
    background-color: #0de3b0;
    color: #ffffff;
  }

  .price-information-title {
    text-align: center;
  }

  .price-information {
    text-align: center;
  }

  .document-count {
    color: #3400c8;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
  }

  .box-due-amount {
    font-size: 14px;
  }

  .price-info-2 {
    font-size: 17px;
  }

  .card-icon-div-2 {
    margin-bottom: 10px !important;
  }

  .payment-label-2 {
    font-size: 14px;
  }

  .button-container-payment2 {
    margin-bottom: 25px;
    margin-top: 27px;
  }

  .payment2-right-part {
    margin-top: 20px;
  }

  .box-step-2 {
    margin-bottom: 0;
  }

  .payment2-right-part-title3 {
    font-size: 12px;
  }

  .payment2-right-part-description {
    font-size: 11px;
  }

  .text-header-payment{
    text-align: center;
    font-size: 12px;
    line-height: 17px;
    font-weight: 400;
  }
}

@media (min-width: 769px) {
  .display-mobile {
    display: none !important;
  }

  .main-content {
    padding-left: 63px;
    padding-right: 63px;
  }

  .MuiContainer-root {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }

  .info-footer {
    padding-top: 5px;
    padding-bottom: 6px;
  }

  .green-container-content {
    padding: 27px 27px 36px 27px;
    background-color: #0de3b0;
    color: #ffffff;
  }

  .white-container-content {
    padding: 28px 72px 30px 72px;
  }

  .white-email-container-right {
    padding: 28px 23px 30px 23px;
  }

  .white-container-search {
    padding-bottom: 75px;
  }

  .right-side-image-container {
    position: relative;
  }

  .right-side-image {
    position: absolute;
    left: -130px;
    bottom: -48px;
  }

  .right-side-image-search {
    left: -178px;
    bottom: -98px;
  }

  .right-side-image-email {
    left: -99px;
    bottom: -19px;
  }

  .main-form-image {
    max-width: 80%;
  }

  .green-container-content-no-image {
    padding: 27px 27px 90px;
    background-color: #0de3b0;
    color: #ffffff;
  }

  .green-container-content-no-image-avis {
    padding: 27px 27px;
    background-color: #0de3b0;
    color: #ffffff;
  }

  .payment2-right-part {
    padding-left: 85px;
  }
}