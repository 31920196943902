@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');

body {
  font-family: Livvic;
  margin: 0px;
}

.grecaptcha-badge {
  bottom: 80px !important;
}